@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-body-image {
  background-image: url("./8799192.jpg");
  /* background-repeat: no-repeat; */
}

.item {
  background-color: transparent;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 0.9vmin solid rgb(108, 171, 238);
  transform-style: preserve-3d;
  transform: rotateX(70deg) translateZ(50px);
  animation: my-move 3s ease-in-out calc(var(--i) * 0.08s) infinite;
  /* box-shadow: 0px 0px 15px rgb(124, 124, 124),
    inset 0px 0px 15px rgb(124, 124, 124); */
  width: calc(var(--i) * 2.5vmin);
}

@keyframes my-move {
  0%,
  100% {
    transform: rotateX(70deg) translateZ(50px) translateY(0px);
    filter: hue-rotate(0deg);
  }
  50% {
    transform: rotateX(70deg) translateZ(50px) translateY(-50vmin);
    filter: hue-rotate(180deg);
  }
}
